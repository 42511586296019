import React, { Suspense, useState } from 'react';
import './App.css';
import './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import components
import Header from './components/Header';
import Footer from './components/Footer';

// Import pages
import Home from './pages/Home';
import About from './pages/About';
import Examples from './pages/Examples'; // New import for Examples page
import { SliderMark } from '@mui/material';
import { hasLoadedNamespace } from 'i18next';

function App() {
  const { i18n } = useTranslation();
  const [token, setToken] = useState(localStorage.getItem('token'));

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleLogin = (newToken) => {
    setToken(newToken);
  };

  return (
    <Router>
      <Suspense fallback="Loading...">
        <div className="flex flex-col min-h-screen">
          {/* Header Section */}
          <Header changeLanguage={changeLanguage} token={token} />

          {/* Main Content */}
          <div className="flex-grow">
            <Routes>
              {/* Existing Routes */}
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/examples" element={<Examples />} /> {/* Add Examples route */}

              {/* Other routes */}
            </Routes>
          </div>

          {/* Footer Section */}
          <Footer />
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
