import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarToday, Inventory2, BarChart, HeadsetMic, Campaign, AttachMoney } from '@mui/icons-material';
import { motion } from 'framer-motion';

function Examples() {
  const { t } = useTranslation();

  return (
    <section className="py-16 px-4 bg-accent">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold text-center text-gray-900">{t('examples.title')}</h2>
        <div className="mt-2 border-b-2 border-gray-300 w-20 mx-auto"></div>
        <p className="text-lg text-gray-600 text-center mt-4">{t('examples.description')}</p>

        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Planning Card */}
          <motion.div
            className="bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3 className="text-2xl font-semibold text-red-500 flex items-center">
              <span className="icon-background mr-2">
                <CalendarToday />
              </span>
              {t('examples.planning.title')}
            </h3>
            <p className="mt-2 text-gray-700 leading-relaxed">{t('examples.planning.description')}</p>
          </motion.div>

          {/* Inventory Management Card */}
          <motion.div
            className="bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <h3 className="text-2xl font-semibold text-red-500 flex items-center">
              <span className="icon-background mr-2">
                <Inventory2 />
              </span>
              {t('examples.inventory.title')}
            </h3>
            <p className="mt-2 text-gray-700 leading-relaxed">{t('examples.inventory.description')}</p>
          </motion.div>

          {/* Reporting Card */}
          <motion.div
            className="bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3 className="text-2xl font-semibold text-red-500 flex items-center">
              <span className="icon-background mr-2">
                <BarChart />
              </span>
              {t('examples.reporting.title')}
            </h3>
            <p className="mt-2 text-gray-700 leading-relaxed">{t('examples.reporting.description')}</p>
          </motion.div>

          {/* Customer Service Card */}
          <motion.div
            className="bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-2xl font-semibold text-red-500 flex items-center">
              <span className="icon-background mr-2">
                <HeadsetMic />
              </span>
              {t('examples.customerService.title')}
            </h3>
            <p className="mt-2 text-gray-700 leading-relaxed">{t('examples.customerService.description')}</p>
          </motion.div>

          {/* Marketing Card */}
          <motion.div
            className="bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3 className="text-2xl font-semibold text-red-500 flex items-center">
              <span className="icon-background mr-2">
                <Campaign />
              </span>
              {t('examples.marketing.title')}
            </h3>
            <p className="mt-2 text-gray-700 leading-relaxed">{t('examples.marketing.description')}</p>
          </motion.div>

          {/* Finance Management Card */}
          <motion.div
            className="bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <h3 className="text-2xl font-semibold text-red-500 flex items-center">
              <span className="icon-background mr-2">
                <AttachMoney />
              </span>
              {t('examples.finance.title')}
            </h3>
            <p className="mt-2 text-gray-700 leading-relaxed">{t('examples.finance.description')}</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Examples;
