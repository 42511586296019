import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Added useLocation and useNavigate
import { useTranslation } from 'react-i18next';

function Header({ changeLanguage }) {
  const { t, i18n } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate(); // Navigation hook
  const location = useLocation(); // Current location

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleContactClick = () => {
    if (location.pathname !== '/') {
      // If not on the home page, navigate to home first
      navigate('/');
      setTimeout(() => {
        document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Timeout ensures smooth scroll after the homepage loads
    } else {
      // If already on the home page, scroll to the contact section
      document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-white shadow-md border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex items-center justify-between md:justify-start">
        <div className="flex items-center">
          <img src={`${process.env.PUBLIC_URL}/logo2.png`} alt="AetherFlare Logo" className="h-10 w-auto" />
          <h1 className="ml-4 text-2xl font-bold text-red-500">AetherFlare</h1>
        </div>

        {/* Right-aligned Desktop Nav and Language Switcher */}
        <div className="hidden md:flex items-center space-x-6 ml-auto">
          <nav className="flex space-x-6">
            <Link to="/" className="text-gray-600 hover:text-red-500">{t('header.home')}</Link>
            <Link to="/examples" className="text-gray-600 hover:text-red-500">{t('header.examples')}</Link>
            <button
              onClick={handleContactClick}
              className="text-gray-600 hover:text-red-500"
            >
              {t('header.contact')}
            </button>
          </nav>

          {/* Language Switcher with Active Language Styling */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => changeLanguage('en')}
              className={`${i18n.language === 'en' ? 'active-language' : 'text-gray-600'} hover:text-red-500`}
            >
              EN
            </button>
            <span className="h-5 border-l border-gray-400"></span>
            <button
              onClick={() => changeLanguage('nl')}
              className={`${i18n.language === 'nl' ? 'active-language' : 'text-gray-600'} hover:text-red-500`}
            >
              NL
            </button>
          </div>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMobileMenu} className="text-gray-600 hover:text-red-500 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white border-t border-gray-200">
          <nav className="flex flex-col items-center space-y-4 py-4">
            <Link to="/" className="text-gray-600 hover:text-red-500" onClick={toggleMobileMenu}>{t('header.home')}</Link>
            <Link to="/examples" className="text-gray-600 hover:text-red-500" onClick={toggleMobileMenu}>{t('header.examples')}</Link>
            <button
              onClick={() => {
                toggleMobileMenu();
                handleContactClick();
              }}
              className="text-gray-600 hover:text-red-500"
            >
              {t('header.contact')}
            </button>
            <div className="flex items-center space-x-4">
              <button onClick={() => changeLanguage('en')} className="text-gray-600 hover:text-red-500">EN</button>
              <button onClick={() => changeLanguage('nl')} className="text-gray-600 hover:text-red-500">NL</button>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
