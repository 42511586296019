// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend'; // Use HTTP backend

i18n
  .use(HttpApi) // Load translations from the backend
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'nl',
    supportedLngs: ['en', 'nl'],
    backend: {
      loadPath: `https://aetherflare.nl/api/locales/{{lng}}`,  // Dynamic loading from backend
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    react: {
      useSuspense: true, // Enable Suspense for async loading
    },
  });

export default i18n;
