import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-900 text-white py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <p className="text-center">&copy; 2024 AetherFlare. {t('footer.rights')}</p>
      </div>
    </footer>
  );
}

export default Footer;
