import React from 'react';
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-gray-900 text-center">{t('about.title')}</h2>
        <p className="mt-8 text-gray-600 text-center">
          {t('about.description1')}
        </p>
        <p className="mt-4 text-gray-600 text-center">
          {t('about.description2')}
        </p>
        <p className="mt-4 text-gray-600 text-center">
          {t('about.description3')}
        </p>
      </div>
    </div>
  );
}

export default About;
